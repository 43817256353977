import request from '@/common/request'

export function getAgents(skip, limit, keyword, isPrivate, tag, order) {
    return request({
        url: '/agent?' + getParams({skip, limit, keyword, isPrivate, tag, order}),
        method: 'GET'
    })
}

export function createAgent(req) {
    return request({
        url: '/agent',
        method: 'POST',
        data: req
    })
}

export function reportUsage(id) {
    return request({
        url: '/agent/' + id + '/usage',
        method: 'PUT'
    })
}

function getParams(params) {
    let query = ""
    for (var k in params) {
        var v = params[k]
        if (v !== null && v !== '') {
            query += k + '=' + v + '&'
        }
    }
    return query
}