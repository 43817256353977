/* eslint-disable no-constant-condition */
/* eslint-disable no-debugger */
import request from '@/common/request'
import { options } from '@/common/options'

export function getConversations() {
    return request({
        url: '/chat/conversation',
        method: 'get'
    })
}

export function getConversation(id) {
    return request({
        url: '/chat/conversation/' + id,
        method: 'get'
    })
}

// export function sendMessage(conversationId, style, agentId, content, model, conversationLimits) {
//     return request({
//         url: '/chat/conversation/' + conversationId + '/message',
//         method: 'post',
//         data: {
//             style,
//             agentId,
//             content,
//             model,
//             conversationLimits
//         }
//     })
// }

export function getModels() {
    return request({
        url: '/chat/model',
        method: 'get'
    })
}

export async function sendMessage(data, onChunk, onError) {
    const bearerToken = localStorage.getItem('BearerToken')
    const url = options.baseURL + '/chat/conversation/' + data.conversationId + '/message'
    const res = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`
        },
        body: JSON.stringify(data)
    })

    if (!res.ok) {
        // var text = await res.body.getReader().read()
        onError(await res.json())
        return
    }

    const reader = res.body.getReader()
    let decoder = new TextDecoder()

    while (true) {
        const { done, value } = await reader.read()
        if (done) break;

        const token = decoder.decode(value)
        onChunk(token)
    }
}

export function clearConversation() {
    return request({
        url: '/chat/conversation',
        method: 'DELETE'
    })
}