<template>
  <div style="height: 100%;">
    <div v-show="loading" style="height: 100%; display: flex; align-items: center; justify-content: center;">
      <div>
        <div>
          <a-avatar src="avatar.png" />
        </div>
        <div style="margin-top: 10px;">
          <LoadingAnimate />
        </div>
      </div>
    </div>
    <ChatModule v-show="!loading" @loaded="loaded" />
  </div>
</template>

<script>
import ChatModule from './components/ChatModule.vue'
import LoadingAnimate from './components/LoadingAnimate.vue'

export default {
  name: 'App',
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      loading: true
    }
  },
  components: {
    ChatModule,
    LoadingAnimate
  },
  methods: {
    loaded() {
      this.loading = false
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%!important;
  min-height: 100%!important;
  overscroll-behavior: none;
  position: relative;
}

body {
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  display: block;
}

.app-mobile {
  padding: 0;
}

.app-desktop {
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

::-webkit-scrollbar {
  cursor: pointer;
  width: 4px;
  height: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  display: none;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: transparent;
  border-radius: 2px;
  -webkit-transition: background-color 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background-color 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
</style>
