<template>
    <div>
        <a-menu-item @click="showPromotionDialog">推广</a-menu-item>
        <a-modal :open="promotionDialogVisible" title="推广 & 奖励" :footer="null" :onCancel="hidePromotionDialog">
            <div style="color: #666;">
                <div>
                    <span>当有人通过您的推广链接或二维码成功注册，您和对方都将获得 <strong>30</strong> 点数，可立即用于对话！</span>
                </div>
            </div>
            <div>
                <a-tabs v-model:activeKey="activeTab">
                    <a-tab-pane key="tab1" tab="推广链接">
                        <div style="text-align: center;">
                            <div class="promotion-qrcode-container" style="display: flex; justify-content: center;">
                                <a-qrcode :value="link" type="canvas" />
                            </div>
                            <a-input :value="link" disabled :style="{ width: '200px', backgroundColor: 'transparent', color: '#666', marginTop: '10px', marginBottom: '10px' }" />
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <a-button @click="handleCopyLink" type="link">复制链接</a-button>
                            <a-button @click="handleDownloadQrcode" type="link">保存图片</a-button>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="tab2" tab="推广记录">
                        <a-list :data-source="referrals">
                            <template #renderItem="{ item }">
                                <div style="display: flex; flex-direction: row; align-items: center;">
                                    <span style="flex-grow: 1;">{{ item.mobilePhone }}</span>
                                    <span style="flex-grow: 1;">{{ item.displayTime }}</span>
                                    <span style="flex-grow: 1;">{{ getDisplayReferralType(item.type) }}</span>
                                    <!-- <span style="flex-grow: 1; text-align: right;">
                                        <a-button disabled v-if="item.received" type="link">已领取</a-button>
                                        <a-button v-model:disabled="receiveButtonDisabled" v-else @click="receiveReferralReward(item.id)" type="link">领取</a-button>
                                    </span> -->
                                </div>
                            </template>
                        </a-list>
                    </a-tab-pane>
                </a-tabs>
            </div>
            <div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { getReferralList, receiveReferralReward, renewToken } from '@/api/member'
import { message } from 'ant-design-vue'

export default {
    props: {
        memberId: Number
    },
    components: {

    },
    computed: {
        link: function() {
            return `https://happyai.chat/?s=${this.memberId}`
        }
    },
    data() {
        return {
            promotionDialogVisible: false,
            activeTab: 'tab1',
            referrals: [],
            receiveButtonDisabled: false,
            requireRefresh: false
        }
    },
    methods: {
        async showPromotionDialog() {
            this.promotionDialogVisible = true
            let res = await getReferralList()
            if (res) {
                this.referrals = res
            }
        },
        hidePromotionDialog() {
            this.promotionDialogVisible = false
            if (this.requireRefresh) {
                window.location.reload()
            }
        },
        getDisplayReferralType(val) {
            if (val == 1) {
                return '注册'
            } else if (val == 2) {
                return '购买'
            } else {
                return '邀请'
            }
        },
        handleCopyLink() {
            this.copyToClip(this.link)
            message.info('复制成功~')
        },
        handleDownloadQrcode() {
            const canvas = document.getElementsByClassName('promotion-qrcode-container')[0].firstChild.children[0]
            const url = canvas.toDataURL()
            const a = document.createElement('a')
            a.href = url
            a.download = 'happyai.jpg'
            a.click()
        },
        copyToClip(text) {
            const input = document.createElement('textarea')
            input.setAttribute('readonly', 'readonly')
            input.value = text
            document.body.appendChild(input)
            input.select()
            if (document.execCommand('copy'))
                document.execCommand('copy')
            document.body.removeChild(input)
        },
        async receiveReferralReward(id) {
            this.receiveButtonDisabled = true
            await receiveReferralReward(id)
            await renewToken()
            if (this.referrals.filter(x => x.id == id).length == 1) {
                this.referrals.filter(x => x.id == id)[0].received = true
            }
            message.info('领取成功~')
            this.requireRefresh = true
            this.receiveButtonDisabled = false
        }
    }
}
</script>

<style scoped>
</style>