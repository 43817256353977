<template>
    <div>
        <a-dropdown-button @click="newConversation" :loading="loading" class="new-conversion-dropdown">
            新建聊天
            <template #overlay>
                <a-menu>
                    <a-menu-item @click="showAgentList(false)">助手列表</a-menu-item>
                    <a-menu-item>清空对话</a-menu-item>
                    <a-menu-item v-if="memberId == 1" @click="showAgentList(true)">新建助手</a-menu-item>
                </a-menu>
            </template>
            <template #icon>
                <DownOutlined />
            </template>
        </a-dropdown-button>
        <a-modal :open="agentListVisible" title="助手列表" :footer="null" :onCancel="closeAgentList" :maskClosable="true" :centered="true" width="80%">
            <div style="height: 80vh; overflow-y: scroll; padding: 5px;">
                <div style="text-align: center; padding-top: 20px; padding-bottom: 20px;">
                    <a-input v-model:value="inputKeyword" placeholder="搜索助手名称、描述或标签..." allow-clear class="input" size="large" :style="{ width: '70%' }">
                        <template #prefix>
                            <SearchOutlined />
                        </template>
                    </a-input>
                </div>
                <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; gap: 20px;">
                    <a-card v-for="(agent) in filteredAgents" v-bind:key="agent.id" :style="{ width: '300px', overflow: 'hidden' }" hoverable @click="newConversation(agent)">
                        <a-card-meta :title="agent.name" :description="agent.description" class="agent-card-meta">
                            <template #avatar>
                                <a-avatar v-if="agent.avatarPath" src="avatar.png" />
                                <span v-else-if="agent.avatar" style="font-size: 30px;">{{ agent.avatar }}</span>
                            </template>
                        </a-card-meta>
                        <div v-if="agent.tags" style="margin-top: 10px;">
                            <a-tag v-for="(t) in agent.tags.split(',')" v-bind:key="t">{{ t }}</a-tag>
                        </div>
                        <div style="position: absolute; bottom: 5px; right: 10px; font-size: 12px; color: #ddd;">
                            <span>对话次数：{{ agent.usedTimes }}</span>
                        </div>
                    </a-card>
                </div>
            </div>
        </a-modal>
        <a-modal :open="newAgentVisible" title="新建助手" :footer="null" :onCancel="closeNewAgent" :maskClosable="true">
            <a-form :model="newAgent">
                <a-form-item label="名称">
                    <a-input v-model:value="newAgent.name" />
                </a-form-item>
                <a-form-item label="描述">
                    <a-input v-model:value="newAgent.description" />
                </a-form-item>
                <a-form-item label="提示词">
                    <a-textarea v-model:value="newAgent.prompt" />
                </a-form-item>
                <a-form-item label="头像">
                    <a-input v-model:value="newAgent.avatar" />
                </a-form-item>
                <a-form-item label="头像路径">
                    <a-input v-model:value="newAgent.avatarPath" />
                </a-form-item>
                <a-form-item label="标签">
                    <a-input v-model:value="newAgent.tags" />
                </a-form-item>
                <a-form-item>
                    <a-upload v-model:file-list="fileList" name="agentFile" @change="handleFileChange">
                        <a-button>
                            上传助手配置
                        </a-button>
                    </a-upload>
                    <a-button @click="createAgent" type="primary">保存</a-button>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { DownOutlined, SearchOutlined } from '@ant-design/icons-vue'
// eslint-disable-next-line no-unused-vars
import { getAgents, createAgent, reportUsage } from '@/api/agent'

export default {
    props: {
        loading: Boolean,
        memberId: Number
    },
    components: {
        DownOutlined, SearchOutlined
    },
    data() {
        return {
            currentCursor: 0,
            fetchLimit: 100,
            inputKeyword: '',
            agents: [],
            isPrivate: false,
            tags: [],
            agentListVisible: false,
            newAgentVisible: false,
            newAgent: {},
            fileList: []
        }
    },
    computed: {
        filteredAgents: function() {
            const kw = this.inputKeyword
            if (kw) {
                return this.agents.filter(x => x.name?.indexOf(kw) != -1 || x.description?.indexOf(kw) != -1 || x.tags?.indexOf(kw) != -1)
            } else {
                return this.agents
            }
        }
    },
    methods: {
        newConversation(agent) {
            this.agentListVisible = false
            this.$emit('newConversation', agent)
        },
        closeAgentList() {
            this.agentListVisible = false
        },
        async showAgentList(createNew) {
            this.agentListVisible = true

            if (!this.agents || this.agents.length == 0) {
                await this.reloadAgents()
            }
            
            if (createNew) {
                this.showNewAgent()
            }
        },
        async reloadAgents() {
            const res = await getAgents(this.currentCursor, this.fetchLimit, this.inputKeyword, this.isPrivate, null, 0)
            if (res) {
                this.agents = res
            }
        },
        showNewAgent() {
            this.newAgentVisible = true
        },
        closeNewAgent() {
            this.newAgentVisible = false
        },
        async createAgent() {
            var succeed = await createAgent({
                name: this.newAgent.name,
                description: this.newAgent.description,
                prompt: this.newAgent.prompt,
                avatar: this.newAgent.avatar,
                avatarPath: this.newAgent.avatarPath,
                tags: this.newAgent.tags
            })

            if (succeed) {
                message.info('保存成功~')
                this.newAgentVisible = false
            }
        },
        handleFileChange(e) {
            const file = e.file.originFileObj
            const reader = new FileReader()

            reader.onload = () => {
                var obj = JSON.parse(reader.result)
                this.newAgent.prompt = obj.config.systemRole
                this.newAgent.name = obj.meta.title
                this.newAgent.description = obj.meta.description
                this.newAgent.tags = obj.meta.tags.join(',')
                this.newAgent.avatar = obj.meta.avatar
            }

            reader.readAsText(file)
        }
    }
}
</script>

<style scoped>
.new-conversion-dropdown {
    display: flex;
}
.new-conversion-dropdown > :first-child {
  flex-grow: 1;
}

.agent-card-meta {
    word-break: break-all;
}

.agent-card-meta > :last-child > :last-child {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.input {
  border-radius: 0;
}
</style>