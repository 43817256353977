/* eslint-disable no-debugger */
import axios from 'axios'
import { message } from 'ant-design-vue'
import { options } from '@/common/options'

const service = axios.create({
    baseURL: options.baseURL,
    timeout: 600000
})

service.interceptors.request.use(
    config => {
        var token = localStorage.getItem('BearerToken')
        config.headers['Authorization'] = `Bearer ${token}`
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code != 0) {
            message.error(res.message || 'Error')
            // return Promise.reject(new Error(res.message || Error))
        } else {
            if (res.data) {
                return res.data
            } else {
                return true
            }
        }
    },
    error => {
        message.error(error.message)
        return Promise.reject(error)
    }
)

export default service