<template>
        <div v-if="!conversationLoading" class="conversations" :class="{ 'desktop-gap': !isMobile }">
            <div class="conversation-item" :class="{ 'conversion-item-selected': currentConversationId == item.id }" v-for="(item) in conversations" @click="handleConversationClick(item.id)" :key="item.id">
            <div style="display: flex; flex-direction: row;">
                <div>
                <a-avatar v-if="item.agentAvatarPath" :src="item.agentAvatarPath" />
                <span v-else-if="item.agentAvatar" style="font-size: 24px; width: 32px;">{{ item.agentAvatar }}</span>
                <a-avatar v-else src="avatar.png" />
                </div>
                <div style="flex-grow: 1; text-align: left; padding-left: 10px; padding-right: 10px;">
                <div style="display: flex; flex-direction: column; width: 100%;">
                    <div style="display: flex; flex-direction: row;">
                    <span :class="{ 'bold_font': currentConversationId == item.id }" style="flex-grow: 1; font-size: 14px;">{{ item.agentName ?? defaultAgentName }}</span>
                    <div style="color: #aaa; float: right;">
                        {{ item.displayTime }}
                    </div>
                    </div>
                    <div style="width: 100%; overflow-x: hidden; margin-top: 5px; margin-bottom: 5px;">
                    <span style="font-size: 12px; color: #aaa; white-space: nowrap; text-overflow: ellipsis; width: 200px;">{{ item.digest }}</span>
                    </div>
                    <div style="flex-grow: 0;">
                    <a-tag>{{ toDisplayModelName(item.model) }}</a-tag>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div v-else style="height: 100%; padding-top: 60px;">
            <LoadingAnimate />
        </div>
</template>

<script>
import LoadingAnimate from './LoadingAnimate.vue'

export default {
    props: {
        conversations: Array,
        conversationLoading: Boolean,
        availableModels: Array,
        currentConversationId: Number
    },
    components: {
        LoadingAnimate
    },
    data() {
        return {
            defaultAgentName: '随便聊聊',
            isMobile: window.innerWidth <= 768
        }
    },
    methods: {
        handleConversationClick(id) {
            this.$emit('conversationClick', id)
        },
        toDisplayModelName(modelId) {
            if (this.availableModels) {
                for (let m of this.availableModels) {
                    if (m.key == modelId) {
                        return m.name
                    }
                }
            }
            return modelId
        }
    }
}
</script>

<style scoped>
.bold_font {
  font-weight: bold;
}

.conversations {
  flex-grow: 1;
  overflow: auto;
}

.conversation-item {
  padding: 0.5rem; 
  border-radius: 0.375rem;
  word-break: break-all;
  cursor: pointer;
  transition: opacity 0.5s ease-in;
  background-color: #fff;
}

.conversation-item div {
  display: flex; 
  flex-direction: row;
}

.conversation-item:hover {
  background-color: rgb(247, 247, 247);
}

.conversion-item-selected {
  background-color: rgb(240, 240, 240);
}

.conversion-item-selected:hover {
  background-color: rgb(224, 224, 224);
}

.desktop-gap {
    margin-top: 20px;
}
</style>