import request from '@/common/request'

export function sendCaptcha(mobilePhone) {
    return request({
        url: '/member/auth/captcha',
        method: 'post',
        data: {
            mobilePhone
        }
    })
}

export function auth(mobilePhone, captcha, ticket, referrer) {
    return request({
        url: '/member/auth',
        method: 'post',
        data: {
            mobilePhone,
            captcha,
            ticket,
            referrer
        }
    })
}

export function getCurrentMember() {
    return request({
        url: '/member/current',
        method: 'get'
    })
}

export function verifyCode(code) {
    return request({
        url: '/member/code/verify',
        method: 'post',
        data: {
            code
        }
    })
}

export function getReferralList() {
    return request({
        url: '/member/referral',
        method: 'get'
    })
}

export function receiveReferralReward(id) {
    return request({
        url: `/member/referral/${id}/receive`,
        method: 'post'
    })
}

export async function renewToken() {
    let res = await request({
        url: '/member/token/renew',
        method: 'get'
    })
    
    if (res?.token) {
        localStorage.setItem('BearerToken', res.token)
    }
}

// function getParams(params) {
//     let query = ""
//     for (var k in params) {
//         var v = params[k]
//         if (v !== '') {
//             query += k + '=' + v + '&'
//         }
//     }
//     return query
// }