<template>
  <div style="height: 100%;">
    <a-layout style="height: 100%; background: transparent;">
      <!--left-->
      <a-layout-sider v-if="!isMobile" width="300px" :style="{ background: 'transparent', borderRight: '1px solid #e5e7eb' }">
        <a-layout class="layout">
          <a-layout-content class="conversation-list">
            <NewConversationButton @newConversation="newConversation" :memberId="member.id" :loading="generating || conversationLoading" />
            <ConversationList v-model:conversations="conversations" :availableModels="availableModels" :currentConversationId="currentConversationId" :conversationLoading="conversationLoading" @conversationClick="handleConversationClick" />
          </a-layout-content>
          <a-layout-footer class="profile-panel">
            <div style="display: flex; flex-direction: row;">
              <div style="width: 40px; display: flex; justify-content: center; align-items: center;">
                <a-avatar src="avatar.png" />
              </div>
              <div style="flex-grow: 1; justify-content: center; align-items: start; display: flex; flex-direction: column;">
                <div>
                  <span v-if="member.displayMobilePhone">{{ member.displayMobilePhone }}</span>
                </div>
                <div v-if="loggedIn()">
                  <a-tag v-if="member.isPlusMember" color="orange">高级会员</a-tag>
                  <a-tag v-else>免费用户</a-tag>
                  <UpgradeDialog type="link" text="购买点数" @tokenChanged="tokenChanged" />
                </div>
                <div v-else>
                  <a-button type="link" @click="showLogin">登录</a-button>
                </div>
              </div>
              <div style="display: flex; align-items: center;">
                <a-dropdown trigger="click" placement="topLeft">
                  <a-button type="text" ghost="true">
                    <template #icon>
                      <MenuOutlined />
                    </template>
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a @click="showContactUs()" href="javascript:;">联系我们</a>
                      </a-menu-item>
                      <PromotionModule v-if="loggedIn()" :memberId="member.id" />
                      <a-menu-divider />
                      <a-menu-item v-if="loggedIn()">
                        <a @click="logout()" href="javascript:;">退出登录</a>
                      </a-menu-item>
                      <a-menu-item v-if="!loggedIn()">
                        <a @click="showLogin" href="javascript:;">登录</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </div>
          </a-layout-footer>
        </a-layout>
      </a-layout-sider>
      <!--right-->
      <a-layout-content class="main">
        <div v-if="isMobile" class="mobile-header">
          <a-button type="link" @click="showConversationDrawer">
            <strong>
              {{ currentConversation?.agentName ?? defaultAgentName }} ({{ conversations.length }})
              <DownOutlined />
            </strong>
          </a-button>
          <div style="position: absolute; top: 8px; right: 0px;">
            <a-button type="ghost" @click="showMenuDrawer">
              <template #icon>
                <MenuOutlined />
              </template>
            </a-button>
          </div>
          <a-drawer :open="conversationDrawerVisible" @close="hideConversationDrawer" height="70%" placement="bottom" title="会话列表" bodyStyle="padding: 12px;">
            <ConversationList v-model:conversations="conversations" :availableModels="availableModels" :currentConversationId="currentConversationId" :conversationLoading="conversationLoading" @conversationClick="handleConversationClick" />
            <template #extra>
              <NewConversationButton :memberId="member.id" @newConversation="newConversation" :loading="generating || conversationLoading" />
            </template>
          </a-drawer>
          <a-drawer :open="menuDrawerVisible" @close="closeMenuDrawer" placement="bottom" :closable="false" height="320px" bodyStyle="padding: 0;">
            <a-menu :style="{ fontSize: '16px' }" :selectable="false" @click="closeMenuDrawer">
              <UpgradeDialog v-if="loggedIn()" type="menu" @tokenChanged="tokenChanged" />
              <a-menu-divider v-if="loggedIn()" />
              <a-menu-item key="2" @click="showContactUs">联系我们</a-menu-item>
              <a-menu-divider v-if="loggedIn()" />
              <PromotionModule v-if="loggedIn()" :memberId="member.id" />
              <a-menu-divider />
              <a-menu-item key="3" @click="logout" v-if="loggedIn()">退出登录</a-menu-item>
              <a-menu-item key="4" @click="showLogin" v-else>登录</a-menu-item>
              <a-menu-divider />
            </a-menu>
            <div style="display: flex; flex-direction: column; margin-top: 40px;">
              <div style="display: flex; justify-content: center; align-items: center;">
                <a-avatar src="avatar.png" />
              </div>
              <div style="flex-grow: 1; justify-content: center; align-items: center; display: flex; flex-direction: column;">
                <div style="margin-top: 5px; margin-bottom: 5px;">
                  <span v-if="member.displayMobilePhone">{{ member.displayMobilePhone }}</span>
                </div>
                <div v-if="loggedIn()">
                  <a-tag v-if="member.isPlusMember" color="orange" :style="{ marginInlineEnd: '0' }">高级会员</a-tag>
                  <a-tag v-else :style="{ marginInlineEnd: '0' }">免费用户</a-tag>
                </div>
                <div v-else>
                  <span>未登录</span>
                </div>
              </div>
            </div>
          </a-drawer>
        </div>
        <div id="message-container" class="message-list">
          <div v-if="!messageLoading" style="height: 100%;">
            <div
              v-for="(message, index) in messages"
              :key="index"
              :class="['message', message.role == 2 ? 'sent' : 'received']"
              @mouseenter="handleMouseEnter(index)"
              @mouseleave="handleMouseLeave"
            >
              <div v-if="message.role == 1" class="avatar-container">
                <a-avatar v-if="currentConversation.agentAvatarPath" :src="currentConversation.agentAvatarPath" />
                <span v-else-if="currentConversation.agentAvatar" style="font-size: 24px; width: 32px;">{{ currentConversation.agentAvatar }}</span>
                <a-avatar v-else src="avatar.png" />
              </div>
              <div v-if="message.role == 2" style="display: inline-flex; align-items: flex-end; order: -2;">
                <a-dropdown :trigger="isMobile ? 'click' : 'hover'">
                  <MoreOutlined />
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a @click="copyMessage(message)" href="javascript:;">复制</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
              <div class="bubble">
                <div style="text-align: left;">
                  <div v-if="message.pending">
                    <LoadingAnimate />
                  </div>
                  <div style="white-space: pre-wrap;" class="markdown-body" v-html="renderMessage(message.content)"></div>
                  <a-image v-if="message.type == 1 && message.imagePath" :width="200" :src="message.imagePath" :style="{ marginTop: '10px', borderRadius: '5px' }" />
                </div>
              </div>
              <div v-if="message.role == 1" style="display: inline-flex; align-items: flex-end;">
                <a-dropdown :trigger="isMobile ? 'click' : 'hover'">
                  <MoreOutlined />
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a @click="copyMessage(message)" href="javascript:;">复制</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
              <div v-if="message.role == 2" class="avatar-container">
                <!-- <img src="avatar.png" alt="用户" class="avatar" /> -->
                <img src="user.png" alt="用户" class="avatar" />
              </div>
            </div>
          </div>
          <div v-else style="padding-top: 60px;">
            <LoadingAnimate />
          </div>
        </div>
        <div class="input-panel">
          <div style="display: flex;">
            <a-button v-if="false" type="text" :ghost="true">
              <template #icon>
                <AudioOutlined/>
              </template>
            </a-button>
            <a-button v-if="false" type="text" :ghost="true">
              <template #icon>
                <DeleteOutlined/>
              </template>
            </a-button>
            <div v-if="allowVision && (uploading || uploadedFilePath)" class="upload-preview-box">
              <a-spin v-if="uploading" />
              <a-image :width="80" :height="80" v-if="uploadedFilePath" :src="uploadedFilePath" :style="{ display: 'inline-block' }" />
              <a-button type="ghost" v-if="uploadedFilePath" @click="removeUploadedFile" :style="{ marginLeft: '10px' }">
                <template #icon>
                  <CloseOutlined />
                </template>
              </a-button>
            </div>
            <a-upload v-if="allowVision" v-model:fileList="uploadFiles" class="upload-image-preview" 
            :before-upload="beforeUpload" :customRequest="processUpload" :maxCount=1 :showUploadList="false"
            accept=".jpg,.png">
              <a-button type="text" :ghost="true">
                <template #icon>
                  <PictureOutlined />
                </template>
              </a-button>
            </a-upload>
            <a-popover trigger="click">
              <template #content>
                <div>
                  <span style="margin-right: 10px;">回复更偏向于</span>
                  <a-radio-group v-model:value="selectedStyle" buttonStyle="solid" style="border-radius: 0;">
                    <a-radio-button value="0">创意</a-radio-button>
                    <a-radio-button value="1">平衡</a-radio-button>
                    <a-radio-button value="2">精确</a-radio-button>
                  </a-radio-group>
                </div>
              </template>
              <a-button type="text" :ghost="true">
                <template #icon>
                  <CoffeeOutlined/>
                </template>
              </a-button>
            </a-popover>
            <a-popover trigger="click">
              <template #content>
                  <div>
                    回复携带历史上下文
                    <a-select v-model:value="selectedMessageHistoriesLimits">
                      <a-select-option value="0">0</a-select-option>
                      <a-select-option value="1">1</a-select-option>
                      <a-select-option value="2">2</a-select-option>
                      <a-select-option value="3">3</a-select-option>
                      <a-select-option value="4">4</a-select-option>
                    </a-select>
                    条
                  </div>
              </template>
              <a-button type="text" :ghost="true">
                <template #icon>
                  <HistoryOutlined/>
                </template>
              </a-button>
            </a-popover>
            <a-select
              style="width: 140px; margin-left: 5px;"
              placeholder="选择一个模型" :bordered="false"
              v-model:value="selectedModel"
              :listHeight="500"
              :dropdownMatchSelectWidth="false"
            >
              <a-select-opt-group v-for="(group) in modelGroups" :label="group.name" :key="group.name">
                <a-select-option v-for="(item) in group.models" :value="item.key" :key="item.key">{{ item.name }}{{ item.outputCredit == 0 ? ' (free)' : '' }}</a-select-option>
              </a-select-opt-group>
            </a-select>
            <div v-if="member.billingMode == 1" style="margin-right: 0px; flex-grow: 1; text-align: right;">
              <span style="margin-top: auto; margin-bottom: auto;" :class="creditUsage > member.credits ? 'font-red' : ''">{{ creditUsage }}/{{ member.credits }}</span>
              <UpgradeDialog type="link" text="购买" />
            </div>
          </div>
          <div style="display: flex; margin-top: 5px; height: auto;">
            <a-textarea enterkeyhint="send" v-model:value="inputMessage" @keypress="handleKeyPress" id="prompt_input" :autoSize="{ minRows: 1, maxRows: 4 }" :style="{ flexGrow: '1', marginRight: '10px', borderRadius: '0' }" placeholder="(Enter 发送 / Shift + Enter 换行)" />
            <a-button type="primary" :disabled="generating || uploading" :style="{ width: '80px', borderRadius: '0' }" @click="sendMessage">
              <template #icon>
                <SendOutlined/>
              </template>
            </a-button>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <a-modal :open="loginVisible" title="悦文" :footer="null" :onCancel="closeLogin">
      <a-form style="margin: 30px">
        <a-form-item>
          <span>对话功能登录后才可以使用~注册即可获得每日免费对话额度</span>
        </a-form-item>
        <a-form-item label="手机号">
          <a-input v-model:value="inputMobilePhone" class="input" />
        </a-form-item>
        <a-form-item label="验证码">
          <div style="display: flex; flex-direction: row;">
            <a-input v-model:value="inputCaptcha" class="input" />
            <a-button @click="sendCaptcha" type="link" :disabled="captchaCooldown" style="display: inline;" class="input">发送验证码<span v-if="captchaCooldownSeconds > 0"> ({{ captchaCooldownSeconds }})</span></a-button>
          </div>
        </a-form-item>
        <a-form-item style="text-align: center;">
          <a-button @click="handleLogin" type="primary" html-type="submit" class="input">注册或登录</a-button>
          <a-button @click="closeLogin" class="input" style="margin-left: 20px;">取消</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal :open="contactUsVisible" title="悦文" :footer="null" :onCancel="closeContactUs" :maskClosable="true">
      <div>
        <span>
          如果您有任何意见、建议或bug反馈，欢迎随时通过以下微信或电子邮件联系我们，感谢您的支持~
        </span>
      </div>
      <div style="text-align: center;">
        <img style="width: 70%;" src="qrcode.jpg" />
      </div>
      <div style="text-align: center;">
        <span>电子邮件：</span>
        <a href="mailto:leavingys@gmail.com">leavingys@gmail.com</a>
      </div>
    </a-modal>
  </div>
</template>

<script>
const PROLOGUE = '你好，我是你的智能助手，你可以问我任何问题，我会尽力回答你。'

import { AudioOutlined, SendOutlined, DeleteOutlined, HistoryOutlined, CoffeeOutlined, MenuOutlined, DownOutlined, MoreOutlined, PictureOutlined, CloseOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { getConversation, getConversations, sendMessage, getModels } from '@/api/chat'
import { sendCaptcha, auth, getCurrentMember, renewToken } from '@/api/member'
import markdownit from 'markdown-it'
import hljs from 'highlight.js'
import UpgradeDialog from './UpgradeDialog.vue'
import LoadingAnimate from './LoadingAnimate.vue'
import NewConversationButton from './NewConversationButton.vue'
import ConversationList from './ConversationList.vue'
import PromotionModule from './PromotionModule.vue'
import COS from 'cos-js-sdk-v5'
import CryptoJS from 'crypto-js'

function highlightBlock(str, lang) {
  return `<pre class="code-block-wrapper"><div class="code-block-header"><span class="code-block-header__lang">${lang}</span><span class="code-block-header__copy"><svg focusable="false" data-icon="copy" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path></svg></span></div><code class="hljs code-block-body ${lang}">${str}</code></pre>`
}

const md = markdownit({
  html: false,
  linkify: true,
  highlight(code, language) {
    const validLang = !!(language && hljs.getLanguage(language))
    if (validLang) {
      const lang = language ?? ''
      return highlightBlock(hljs.highlight(code, { language: lang }).value, lang)
    }
    return highlightBlock(hljs.highlightAuto(code).value, '')
  }
})

export default {
  name: 'ChatModule',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SendOutlined, AudioOutlined, DeleteOutlined, HistoryOutlined, CoffeeOutlined, MenuOutlined, DownOutlined, MoreOutlined, PictureOutlined, CloseOutlined,
    // eslint-disable-next-line vue/no-unused-components
    UpgradeDialog, LoadingAnimate, NewConversationButton, ConversationList, PromotionModule
  },
  data() {
    return {
      defaultAgentName: '随便聊聊',
      conversations: [],
      currentConversationId: 0,
      currentConversation: {},
      hoverIndex: null,
      loginVisible: false,
      contactUsVisible: false,
      conversationDrawerVisible: false,
      menuDrawerVisible: false,
      member: {},
      messages: [],
      selectedModel: 'gpt-4o-mini',
      selectedStyle: '1',
      selectedMessageHistoriesLimits: '1',
      selectedAgent: {},
      inputMobilePhone: '',
      inputCaptcha: '',
      inputMessage: '',      
      availableModels: [],
      generating: false,
      loadingMessages: false,
      isMobile: window.innerWidth <= 768,
      captchaCooldown: false,
      captchaCooldownSeconds: 0,
      conversationLoading: false,
      messageLoading: false,
      cos: new COS({ SecretId: 'AKIDnYDmFc5b3YArgdXKGHSco8QcgcbN0Uro', SecretKey: '8Fa5gCauQrDcwQuNgrwsgQ5d9XiieR4Z' }),
      uploading: false,
      uploadedFilePath: '',
      uploadFiles: [],
      modelGroups: []
    };
  },
  computed: {
    allowVision: function() {
      var model = this.availableModels.filter(x => x.key == this.selectedModel)[0]
      return model?.allowVision ?? false
    },
    creditUsage: function() {
      var arr = this.availableModels.filter(x => x.key == this.selectedModel)
      if (arr.length == 1) {
        var model = arr[0]
        var credit = model.outputCredit
        var contextLength = parseInt(this.selectedMessageHistoriesLimits)
        credit = credit + contextLength * model.inputCredit
        return credit
      }
      return 0
    }
  },
  async mounted() {
    await this.initComponents()
  },
  methods: {
    async initComponents() {
      this.availableModels = await getModels()
      this.modelGroups = []
      for (let i = 0; i < this.availableModels.length; i++) {
        let model = this.availableModels[i]
        let groups = this.modelGroups.filter(x => x.name == model.group)
        if (groups.length == 0) {
          this.modelGroups.push({
            name: model.group,
            models: [model]
          })
        } else {
          var group = groups[0]
          group.models.push(model)
        }
      }

      if (this.loggedIn() && await this.checkTokenExpiration()) {
        this.conversationLoading = true
        this.conversations = await getConversations()
        this.conversationLoading = false
        const member = await getCurrentMember()
        if (member) {
          this.member = member
          this.newConversation()
        }
      } else {
        this.conversations = []
        this.member = {}
        this.newConversation()
      }

      this.$emit('loaded')
    },
    async checkTokenExpiration() {
      const token = localStorage.getItem('BearerToken')
      const expiration = this.parseTokenExpirationDate(token)
      const now = new Date()
      const daysBeforeExpiration = (expiration - now) / (1000 * 60 * 60 * 24)
      if (daysBeforeExpiration <= 7) {
        if (daysBeforeExpiration < 0) {
          // 已过期 无法自动续期
          localStorage.removeItem('BearerToken')
          return false
        } else {
          await renewToken()
        }
      }

      return true
    },
    parseTokenExpirationDate(token) {
      const parts = token.split('.');
      const payload = parts[1];
      const decodedPayload = atob(payload);
      const payloadObj = JSON.parse(decodedPayload);
      const exp = payloadObj.exp;
      return new Date(exp * 1000);
    },
    handleMouseEnter(index) {
      this.hoverIndex = index;
    },
    handleMouseLeave() {
      this.hoverIndex = null;
    },
    showLogin() {
      this.loginVisible = true
    },
    closeLogin() {
      this.loginVisible = false
    },
    async handleLogin() {
      const queries = new URLSearchParams(window.location.search)
      let referrer = queries.get('s')
      if (!referrer) {
        referrer = null
      }

      if (!this.ticket) {
        message.error('请先发送验证码~')
        return
      }

      const res = await auth(this.inputMobilePhone, this.inputCaptcha, this.ticket, referrer)
      if (res && res.token) {
        localStorage.setItem('BearerToken', res.token)
        message.info('登录成功')
        await this.initComponents()
        this.closeLogin()
      } 
    },
    async sendCaptcha() {
      this.captchaCooldown = true
      const res = await sendCaptcha(this.inputMobilePhone)
      if (res?.ticket) {
        this.ticket = res.ticket

        this.captchaCooldownSeconds = 60
        const app = this
        const i = setInterval(() => {
          app.captchaCooldownSeconds--;
          if (app.captchaCooldownSeconds < 1) {
            app.captchaCooldown = false
            clearInterval(i)
          }
        }, 1000);
      } else {
        this.captchaCooldown = false
      }
    },
    loggedIn(showDialog) {
      const loggedIn = localStorage.getItem('BearerToken') || false

      if (!loggedIn && showDialog) {
        this.showLogin()
      }
      return loggedIn
    },
    async sendMessage() {
      if (!this.loggedIn(true) || this.generating) {
        return
      }

      if (!this.inputMessage) {
        message.warn('请先输入您要表达的内容~')
        return
      }

      if (this.member.billingMode == 1 && this.creditUsage > this.member.credits) {
        message.error('您的点数不足，请购买点数，或更换为免费模型~')
        return
      }

      var prompt = this.inputMessage
      var imagePath = this.uploadedFilePath
      this.uploadedFilePath = ''
      this.uploadFiles = []
      this.inputMessage = ''
      this.generating = true

      let msg = ''
      let app = this

      this.messages.push({
        conversationId: this.currentConversationId,
        content: prompt,
        time: new Date().toDateString(),
        role: 2,
        style: 1,
        imagePath,
        type: imagePath ? 1 : 0
      })
      this.scrollMessagesToBottom()

      this.messages.push({
        conversationId: this.currentConversationId,
        content: '',
        time: new Date().toDateString(),
        role: 1,
        style: 1,
        pending: true
      })
      this.scrollMessagesToBottom()

      let cs = this.conversations.filter(x => x.id == this.currentConversationId)
      if (cs.length == 1) {
        cs[0].model = this.selectedModel
      }

      await sendMessage({
        conversationId: this.currentConversationId,
        style: parseInt(this.selectedStyle),
        agentId: this.selectedAgent?.id ?? 0,
        content: prompt,
        model: this.selectedModel,
        conversationLimits: parseInt(this.selectedMessageHistoriesLimits),
        imagePath: imagePath
      }, function(token) {
        const index = token.indexOf('__END__')
        if (index != -1) {
          if (index != 0) {
            const word = token.substring(0, index)
            msg += word
            app.messages[app.messages.length - 1].pending = false
            app.messages[app.messages.length - 1].content = msg
            app.scrollMessagesToBottom()
            token = token.substring(index)
          }
          app.generating = false
          app.attachCopyCodeEvents()
          token = token.substring(8)
          const res = JSON.parse(token)
          if (res) {
            if (res.conversationId) {
              if (app.currentConversationId == 0) {
                app.currentConversationId = res.conversationId
                var conversations = app.conversations.filter(x => x.id == 0)
                if (conversations.length == 1) {
                  conversations[0].id = res.conversationId
                }
              }
            }
            if (res.creditBalance || res.creditBalance == 0) {
              app.member.credits = res.creditBalance
            }
          }
        } else {
          msg += token
          app.messages[app.messages.length - 1].pending = false
          app.messages[app.messages.length - 1].content = msg
          app.scrollMessagesToBottom()
        }
      }, function(res) {
        message.error(res.message)
        app.messages.pop()
        app.generating = false
      })
    },
    async handleConversationClick(id) {
      if (this.generating) {
        message.warn('请等待当前会话回复结束~')
        return
      }
      this.removeCopyCodeEvents()
      if (id == 0) {
        this.messages = [{
          content: this.getConversationPrologue(),
          role: 1
        }]
        this.conversationDrawerVisible = false
      } else {
        this.messageLoading = true
        this.conversationDrawerVisible = false
        const res = await getConversation(id)
        this.messageLoading = false
        this.messages = res.messages
        this.scrollMessagesToBottom()
        this.attachCopyCodeEvents()
      }
      
      this.currentConversationId = id
      if (!this.isMobile && this.loggedIn()) {
        this.focusInput()
      }
      
      var conversation = this.conversations.find(x => x.id == id)
      if (conversation) {
        this.selectedModel = conversation.model
        this.currentConversation = conversation
      }
    },
    getConversationPrologue() {
      if (this.selectedAgent?.name) {
        let p = `你好，我是${this.selectedAgent.name}，${this.selectedAgent.description}`
        if (!p.endsWith('，') && !p.endsWith('。') && !p.endsWith('！') && !p.endsWith(',') && !p.endsWith('.')) {
          p += '，'
        }
        p += '让我们开始对话吧！'
        return p
      } else {
        return PROLOGUE
      }
    },
    newConversation(agent) {
      if (agent && !(agent instanceof PointerEvent) && agent.id) {
        this.selectedAgent = agent
      } else {
        this.selectedAgent = {}
      }
      // 如果存在新会话则直接选中 不再新建
      if (this.conversations.filter(x => x.id == 0).length == 0) {
        var now = new Date()
        this.conversations.splice(0, 0, {
          id: 0,
          model: this.selectedModel,
          digest: this.getConversationPrologue().substring(0, 15),
          agentName: '随便聊聊',
          agentAvatarPath: 'avatar.png',
          agentId: this.selectedAgent ? this.selectedAgent.id : null,
          displayTime: `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`
        })
      }

      if (this.selectedAgent) {
        this.conversations[0].agentAvatar = this.selectedAgent.avatar
        this.conversations[0].agentAvatarPath = this.selectedAgent.avatarPath
        this.conversations[0].agentName = this.selectedAgent.name
        this.conversations[0].digest = this.getConversationPrologue().substring(0, 15)
      }
      
      this.handleConversationClick(0)
    },
    async handleKeyPress(event) {
      if (event.key == 'Enter' && !event.shiftKey) {
        event.preventDefault()
        if (this.generating) {
          return
        }
        await this.sendMessage()
      }
    },
    scrollMessagesToBottom() {
      var container = this.$el.querySelector('#message-container')
      this.$nextTick(() => {
          container.scrollTop = container.scrollHeight
      })
    },
    renderMessage(value) {
      return md.render(value).trim()
    },
    attachCopyCodeEvents() {
      this.$nextTick(() => {
        const buttons = this.$el.querySelectorAll('.code-block-header__copy')
        if (buttons) {
          let app = this
            buttons.forEach((btn) => {
            btn.addEventListener('click', () => {
              const code = btn.parentElement?.nextElementSibling?.textContent
              if (code) {
                app.copyToClip(code)
                message.info('已复制~') 
              } else {
                message.warn('no code found')
              }
            })
          })
        }
      })
    },
    removeCopyCodeEvents() {
      const buttons = this.$el.querySelectorAll('.code-block-header__copy')
      if (buttons) {
        buttons.forEach((btn) => {
          btn.removeEventListener('click', () => {})
        })
      }
    },
    copyToClip(text) {
      const input = document.createElement('textarea')
      input.setAttribute('readonly', 'readonly')
      input.value = text
      document.body.appendChild(input)
      input.select()
      if (document.execCommand('copy'))
        document.execCommand('copy')
      document.body.removeChild(input)
    },
    async tokenChanged() {
      window.location.reload()
      // await this.initComponents()
    },
    focusInput() {
      var input = this.$el.querySelector('#prompt_input')
      input.focus()
    },
    showConversationDrawer() {
      this.conversationDrawerVisible = true
    },
    hideConversationDrawer() {
      this.conversationDrawerVisible = false
    },
    async logout() {
      localStorage.removeItem('BearerToken')
      this.member = {}
      await this.initComponents()
    },
    showContactUs() {
      this.contactUsVisible = true
    },
    closeContactUs() {
      this.contactUsVisible = false
    },
    showMenuDrawer() {
      this.menuDrawerVisible = true
    },
    closeMenuDrawer() {
      this.menuDrawerVisible = false
    },
    copyMessage(m) {
      this.copyToClip(m.content)
      message.info('复制成功~')
    },
    showAgentList() {
      alert('show')
      this.agentListVisible = true
      this.agentsLoading = true
      
    },
    closeAgentList() {
      this.agentListVisible = false
    },
    calculateFileSha1(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          try {
            const data = event.target.result
            const hash = CryptoJS.SHA1(data).toString()
            resolve(hash)
          } catch (error) {
            reject(error)
          }
        }
        reader.readAsBinaryString(file)
      })
    },
    // eslint-disable-next-line no-unused-vars
    beforeUpload(file, fileList) {
      this.uploading = true
      if (file.size / 1024 / 1024 > 5) {
        message.error('请选择5M以内的图片上传~')
        this.uploading = false
        return false
      }
      return true
    },
    processUpload(p) {
      const app = this
      this.calculateFileSha1(p.file).then((hash) => {
        const parts = p.file.name.split('.')
        const ext = parts[parts.length - 1]
        const path = `images/${hash}.${ext}`
        this.cos.uploadFile({
          Bucket: 'msg-1253930261',
          Region: 'ap-hongkong',
          Key: path,
          Body: p.file,
          onProgress: function(progressData) {
            // console.log(progressData)
            p.onProgress({ percent: progressData.percent })
          }
        }, function(err, data) {
          if (err) {
            // console.log(err)
            // console.log('上传失败')
            p.onError(err, data)
          } else {
            app.uploadedFilePath = 'https://' + data.Location
            p.onSuccess(data)
          }
          app.uploading = false
        })
      }).catch((error) => {
        p.onError(error)
      })
    },
    removeUploadedFile() {
      this.uploadedFilePath = ''
      this.uploadFiles = []
    }
  }
}
</script>

<style lang="less">
@import url(./style.less);
</style>

<style scoped>
.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.layout {
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
}

.input {
  border-radius: 0;
}

.conversation-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.bold_font {
  font-weight: bold;
}

.message-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.message {
  display: flex;
  margin: 10px 0;
  /* align-items: center; */
}

.sent {
  justify-content: flex-end;
}

.received {
  justify-content: flex-start;
}

.avatar-container {
  display: flex;
  height: 100%;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.bubble {
  position: relative;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  max-width: 70%;
  margin: 0 10px;
}

.sent .bubble {
  background-color: #d1edff;
  order: -1; /* 改变flex子项的顺序 */
}

.icons {
  display: none;
  position: absolute;
  bottom: -30px;
  left: 0; /* 对于发送的消息，这将被覆盖 */
  white-space: nowrap;
}

.sent .icons {
  left: auto;
  right: 0;
}

.message:hover .icons {
  display: flex;
}

.icon {
  margin: 0 5px;
  cursor: pointer;
}

.input-panel {
  padding: 5px 20px 20px 20px
}

.profile-panel {
  border-top: 1px solid #e5e7eb;
  background: transparent;
  padding: 10px;
}

.mobile-header {
  height: 48px;
  padding-top: 8px;
  /* padding-top: 7px; */
  background-color: rgb(248, 248, 248);
}

.font-red {
  color: red;
}

.upload-preview-box {
  height: 100px;
  width: 132px;
  padding: 10px;
  position: absolute;
  bottom: 95px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  overflow: hidden;
}
</style>
